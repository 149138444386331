<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Collaboration Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <fieldset :disabled="!isCollaborationActive || !isUserCollaborationMaster">
          <base-input
            v-model="collaborationForm.collaborationTitle"
            type="text"
            container-class="mb-4 flex items-center"
            label="Collaboration Title"
            :error="!collaborationForm.collaborationTitle"
            :error-text="`Enter a title to Submit the form`"
            label-class="label-w-46"
          />
          <base-input
            v-model="collaborationForm.description"
            text-area
            rows="5"
            container-class="mb-4 flex"
            label="Collaboration Description"
            label-class="label-w-46 mt-1"
          />
        </fieldset>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useCollaborationForm from './collaborationForm.js';

export default {
    name: 'CollaborationDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue'))
    },

    setup (props, { emit }) {
        const { collaborationForm, isCollaborationActive, isUserCollaborationMaster } = useCollaborationForm();

        return {
            collaborationForm,
            isCollaborationActive,
            isUserCollaborationMaster
        };
    }
};
</script>
